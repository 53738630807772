@use "sass:math";

@import "./variables";
@import "./placeholders";

@mixin padding-and-margin($direction: right, $value: 1rem){
    margin: 0;
    padding: 0;
    margin-#{$direction}: $value;
    padding-#{$direction}: $value;
}

@mixin border($direction: right, $value: 6px solid #242424){
    border: none;
    border-#{$direction}: $value;
}

@mixin snack-bar-status($background-color, $color){
    background-color: $background-color;

    .snack-bar__action {
        color: $color;
    }
}

@mixin transition($transition...){
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin dialog-height($value, $min-height-to-break, $and-max: false){
    @media(min-height: $min-height-to-break){
        max-height: $value !important;
    }

    @if($and-max){
        @media(max-height: $min-height-to-break){
            max-height: $value !important;
        }
    }
}

@mixin card-size(){
    max-width: 600px;
    width: 100%;
}

@mixin card(){
    @include card-size();

    padding: 2rem;
    border: var(--border);
    border-radius: var(--mat-border-radius);

    @media(max-width: $tablet){
        padding: 1.5rem;
    }
}

@mixin content-size($size){
    width: $size;
    max-width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
    max-height: $size;
}

@mixin icon-size($size){
    font-size: $size;
    width: $size;
    max-width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
    max-height: $size;
    line-height: $size;
}

@mixin navigation-drawer-head(){
    $space: 1.5rem;

    padding: $space $space 0 $space;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: $space;
}

@mixin navigation-drawer-title(){
    margin: 0;
    line-height: 1.25;
    font-size: 1.375rem;
    font-weight: 500;
    color: var(--mat-primary-color);
}

@mixin small-imgs-height($value: inherit){
    max-height: $value;
    min-height: $value;
    height: $value;
}

@mixin fix-width($value: inherit){
    max-width: $value;
    min-width: $value;
    width: $value;
}

@mixin center{
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false){
    $padding: unquote(math.div($y, $x) * 100 + '%');

    @if $pseudo{
        &:before{
            @include pseudo($pos: relative);

            width: 100%;
            padding-top: $padding;
        }
    } @else{
        padding-top: $padding;
    }
}

@mixin font($size: false, $colour: false, $weight: false,  $lh: false) {
    @if $size{ font-size: $size; }
    @if $colour{ color: $colour; }
    @if $weight{ font-weight: $weight; }
    @if $lh{ line-height: $lh; }
}

@mixin placeholder {
    &.placeholder{ @content; }
    &:-moz-placeholder{ @content; }
    &::-moz-placeholder{ @content; }
    &:-ms-input-placeholder{ @content; }
    &::-webkit-input-placeholder{ @content; }
}

@mixin hardware($backface: true, $perspective: 1000) {
    @if $backface {
        backface-visibility: hidden;
    }
    perspective: $perspective;
}

@mixin truncate($width){
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin link-style(){
    @extend %no-selection;

    margin: 0;
    padding: 0;
    text-decoration: none !important;
    color: var(--mdc-theme-primary);
    cursor: pointer;
    transition: box-shadow var(--time-transition);
    line-height: 1.25 !important;
    display: inline-block;

    &:hover, &:focus, &:active{
        box-shadow: var(--link-shadow) var(--mdc-theme-primary);
    }
}

@mixin avatar-size($size){
    ::ng-deep .avatar, .avatar{
        @include content-size($size);

        .avatar__link{
            @include content-size($size);
        }

        .avatar__icon{
            width: $size;
            height: $size;
            font-size: $size;
            line-height: $size;
        }
    }
}
